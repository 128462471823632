<template>
    <div class="content_wrapper_workinprogress">
        <img srcset="./../../assets/img/workinprogress.png" class="img-responsive" alt="work in progress in progress" />
        <h3> We are working on this so that you can know us better.</h3>
    </div>
</template>

<script>
export default {
  name: 'webemailhosting'
}
</script>

<style>

</style>